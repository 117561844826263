import React from "react"
import { Helmet } from "react-helmet"
import ogp_image from "@images/kihara-building.png"
import useSiteMetadata from "@utils/use-site-metadata"

const Meta = props => {
  //get SiteMetaData from gatsby-config.js
  const SiteMetaData = useSiteMetadata();
  const baseTitle = SiteMetaData.title
  const url = SiteMetaData.siteURL
  const title = props.title ? `${props.title} | ${baseTitle}` : baseTitle
  return (
    <Helmet>
      <html lang="ja" />
      <title>{title}</title>
      <meta name="description" content={props.desc} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={`${url}${ogp_image}`} />
      <meta property="og:url" content={url} />
      <meta property="og:description" content={props.desc} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={props.desc} />
      <meta name="twitter:image" content={`${url}${ogp_image}`} />
      <body className="font-body" />
    </Helmet>
  )
}

export default Meta;